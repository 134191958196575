import { Checkbox } from "@mantine/core";
import { useState, useEffect } from "react";

export const SelectAllHeader = (props) => {
  const [checked, setChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(
    props.selectedRows.length || 0
  );

  useEffect(() => {
    setSelectedCount(props.selectedRows.length || 0);
  }, [props.selectedRows]);

  const onCheckboxChange = (e) => {
    setChecked(e.currentTarget.checked);

    if (e.currentTarget.checked) {
      const allIds = [];
      props.api.forEachNodeAfterFilterAndSort((node) => {
        const id = node.data.id;
        allIds.push(id);
      });
      props.selectRowsBulk(allIds);
    } else {
      props.selectRowsBulk([]);
    }
  };

  return (
    <>
      <Checkbox checked={checked} onChange={onCheckboxChange} />
      {selectedCount > 0 && (
        <h6 style={{ marginLeft: "4px" }}>{selectedCount}</h6>
      )}
    </>
  );
};
