import {
  createStyles,
  Header,
  Group,
  ActionIcon,
  Container,
  Text,
  rem,
  Menu,
  Button,
  ScrollArea,
  Checkbox,
} from "@mantine/core";
import {
  IconReload,
  IconTrash,
  IconPlus,
  IconFileDownload,
  IconFilter,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: rem(56),
    color: "#fff",

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

export function HeaderMiddle({ props }) {
  const { classes, cx } = useStyles();

  return (
    <Header backgroundcolor={"#3c3c3d"} height={56} mb={0}>
      <Container className={classes.inner}>
        <Group>
          <Text>Spot Admin Tool</Text>
        </Group>

        <Group spacing={0} position="right" noWrap>
          <ActionIcon
            size="lg"
            id="create"
            onClick={(e) => {
              e.stopPropagation();
              props.addClicked();
            }}
          >
            <IconPlus size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            id="refresh"
            onClick={(e) => {
              e.stopPropagation();
              props.refreshClicked();
            }}
          >
            <IconReload size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            disabled={!props.showBulkDeleteIcon}
            size="lg"
            id="delete"
            onClick={(e) => {
              e.stopPropagation();
              props.deleteClicked();
            }}
          >
            <IconTrash size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            id="download"
            onClick={(e) => {
              e.stopPropagation();
              props.downloadClicked();
            }}
          >
            <IconFileDownload size="1.1rem" stroke={1.5} />
          </ActionIcon>
        </Group>

        <Group>
          <ColumnDropdown
            cols={props.colsToShow}
            setCols={props.setColsToShow}
            initialColsToShow={props.initialColsToShow}
            user={props.user}
            gridApi={props.gridApi}
          />
        </Group>
      </Container>
    </Header>
  );
}

const ColumnDropdown = ({
  cols,
  setCols,
  user,
  initialColsToShow,
  gridApi,
}) => {
  const updateCol = (colToUpdate) => {
    const colIndex = cols.findIndex((col) => col.field === colToUpdate.field);
    const updatedCols = [...cols];

    updatedCols.splice(colIndex, 1, {
      ...colToUpdate,
      hide: !colToUpdate.hide,
    });
    setCols(updatedCols);

    // Update the hidden columns in local storage to persist column state
    let updatedHiddenCols = [];
    const hiddenCols = JSON.parse(localStorage.getItem(user.id)) || [];
    if (colToUpdate.hide && hiddenCols.includes(colToUpdate.field)) {
      const index = hiddenCols.findIndex((col) => col === colToUpdate.field);

      hiddenCols.splice(index, 1);
      updatedHiddenCols = [...hiddenCols];
    } else {
      updatedHiddenCols = [...hiddenCols, colToUpdate.field];
    }

    localStorage.setItem(user.id, JSON.stringify(updatedHiddenCols));

    // update the hidden columns in "columnState" in local storage
    const savedState = localStorage.getItem("columnState");
    if (savedState) {
      const columnState = JSON.parse(savedState);
      const updatedColumnState = columnState.map((col) => {
        return col.colId === colToUpdate.field
          ? {
              ...col,
              hide: !col.hide,
            }
          : col;
      });

      localStorage.setItem("columnState", JSON.stringify(updatedColumnState));
    }
  };

  const restoreDefaultCols = () => {
    localStorage.removeItem(user.id);
    localStorage.removeItem("columnState");
    setCols([...initialColsToShow]);
  };

  const clearAllFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged();
    }
  };

  return (
    <Group>
      <Menu shadow="md" width={200} closeOnItemClick={false}>
        <Menu.Target>
          <Button>Columns</Button>
        </Menu.Target>

        <Menu.Dropdown>
          <ScrollArea h={250}>
            {cols.map((col) => {
              return (
                <Menu.Item component="div" key={col.field}>
                  <Checkbox
                    label={col.headerName}
                    checked={!col.hide}
                    onChange={() => updateCol(col)}
                  />
                </Menu.Item>
              );
            })}
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>

      <Button onClick={restoreDefaultCols}>Restore Defaults</Button>
      <Button onClick={clearAllFilters}>Clear Filters</Button>
    </Group>
  );
};
