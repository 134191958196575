export const GET_ALL_SUGGESTED_RATES = `
    query getSuggestedRates($active: Int, $limit: Int, $skip: Int) {
        getSuggestedRates(active: $active, limit: $limit, skip: $skip) {
            id
            customerName
            ruleName
            company
            billTo
            isHazmat
            isTeamRequired
            isAsset
            isBrokerage
            isTempProtectRequired
            minimumMiles
            maximumMiles
            effectiveDttm
            expirationDttm
            createdBy
            createdDttm
            updatedBy
            updatedDttm
            origin {
                days
                location {
                    state
                    metro
                    zip
                    zone
                }
                loadType
                hourOverride
            }
            destination {
                days
                location {
                    state
                    metro
                    zip
                    zone
                }
                loadType
                hourOverride
            }
            modifiers {
                percentModifier
                flatModifier
                minimumCharge: linehaulMin
                maximumCharge: linehaulMax
                rpmMin: RPMMin
                rpmMax: RPMMax
                RPMModifier
            }
            modes {
                tractorMode
                trailerMode
            }
            desirabilityScoreAsset
            desirabilityScoreBrokerage
        }
    }
`;


export const GET_FEATURE_FLAGS = `query getFeatureFlags($platform: FeatureFlagPlatform, $environment: WebEnvironment) {getFeatureFlags(platform: $platform, environment: $environment) {name, permissions {users}}}`;
