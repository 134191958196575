import { ratesApi } from "./api";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    [ratesApi.reducerPath]: ratesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([ratesApi.middleware]),
});
