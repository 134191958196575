import React, { useEffect, useState } from "react";
import "./CreateModal.scss";
import {
  stateCodes,
  desirabilityOptions,
  driverModeOptions,
  trailerModes,
  pickDropOptions,
  customerNames,
  days,
} from "../../../static/data/validOptions";
import loadingIcon from "../../../static/icons/rings.svg";
import {
  Modal,
  Button,
  Checkbox,
  MultiSelect,
  NumberInput,
  Select,
  TextInput,
  Group,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  useBulkDeleteMutation,
  useCreateRateMutation,
  useUpdateRateMutation,
} from "../../../Store/api";

const zones = require("../../../static/data/zones.json");
const metros = require("../../../static/data/metros.json");

function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const errorMessages = {
  dateMissing: "Both effective and expiry dates are required.",
  invalidMinMax: "Min value must be less than Max value if both are present",
  ruleNameMissing: "A rule name is required.",
  noPremiumEntered:
    "One of the seven premiums or linehaul/rpm limiters are required",
  assetBrokerageMissing: "Please select either Asset or Brokerage",
  effectiveDateAfterExpiryDate: "Effective date must be before expiry date",
};

const getInitialValues = (editData) => {
  if (editData) {
    return {
      assetBrokerage: editData.isBrokerage
        ? "brokerage"
        : editData.isAsset
        ? "asset"
        : "",
      billTo: editData.billTo,
      customerName: editData.customerName,
      desirability: editData.desirability,
      driverMode: editData.driverMode,
      dropoffType: editData.loadType,
      dsDays: editData.dsDays,
      dsMetro: editData.dsMetro,
      dsState: editData.dsState,
      dsZip: editData.dsZip.length ? editData.dsZip : undefined,
      dsZone: editData.dsZones,
      effectiveDate: new Date(editData.effectiveDttm),
      expiryDate: new Date(editData.expirationDttm),
      flatPremium: isNaN(parseFloat(editData.flat))
        ? ""
        : parseFloat(editData.flat),
      isHazmat: editData.hazmat === "Y",
      maximumCharge: isNaN(parseFloat(editData.maximumCharge))
        ? ""
        : parseFloat(editData.maximumCharge),
      minimumCharge: isNaN(parseFloat(editData.minimumCharge))
        ? ""
        : parseFloat(editData.minimumCharge),
      rpmMin: isNaN(parseFloat(editData.rpmMin))
        ? ""
        : parseFloat(editData.rpmMin),
      rpmMax: isNaN(parseFloat(editData.rpmMax))
        ? ""
        : parseFloat(editData.rpmMax),
      orDays: editData.orDays,
      orMetro: editData.orMetro,
      orState: editData.orState,
      orZip: editData.orZip.length ? editData.orZip : undefined,
      orZone: editData.orZones,
      overMiles: editData.overMiles ?? "",
      percentagePremium: isNaN(parseFloat(editData.percentModifier))
        ? ""
        : parseFloat(editData.percentModifier),
      pickupType: editData.originLoadType,
      rpmPremium: isNaN(parseFloat(editData.RPM))
        ? ""
        : parseFloat(editData.RPM),
      ruleName: editData.ruleName ?? "",
      trailerMode: editData.trailerMode,
      underMiles: editData.underMiles ?? "",
    };
  } else {
    return {};
  }
};

export const RateModal = (props) => {
  const [orStates, setOrStates] = useState([]);
  const [dsStates, setDsStates] = useState([]);
  const [orMetros, setOrMetros] = useState([]);
  const [dsMetros, setDsMetros] = useState([]);

  const [filteredOrMetros, setFilteredOrMetros] = useState(metros);
  const [filteredDsMetros, setFilteredDsMetros] = useState(metros);

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedBillTos, setSelectedBillTos] = useState([]);

  const customerOptions = customerNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  // Aggregate billTo numbers based on selected customers
  const getBillToOptions = () => {
    const billTos = new Set(); // Use a Set to avoid duplicate billTo numbers
    selectedCustomers.forEach((customerName) => {
      const customer = customerNames.find(
        (c) => c.customerName === customerName
      );
      customer?.billTo.forEach((billTo) => billTos.add(billTo.toString())); // Convert billTo to string to ensure compatibility with MultiSelect
    });
    return Array.from(billTos).map((billTo) => ({
      value: billTo,
      label: billTo,
    }));
  };

  useEffect(() => {
    if (orStates.length > 0) {
      const newMetros = metros.filter((metro) =>
        orStates.some((state) => metro.startsWith(state + "-"))
      );
      setFilteredOrMetros(newMetros);

      // If editing and no state selected, keep original metros; otherwise, filter
      if (!props.editData || orStates.length) {
        setOrMetros(
          orMetros.filter((metro) =>
            orStates.some((state) => metro.startsWith(state + "-"))
          )
        );
      }
    } else {
      setFilteredOrMetros(metros);
      // If not editing, reset metros when no state is selected
      if (!props.editData) {
        setOrMetros([]);
      }
    }
  }, [orStates, props.editData]);

  useEffect(() => {
    if (dsStates.length > 0) {
      const newMetros = metros.filter((metro) =>
        dsStates.some((state) => metro.startsWith(state + "-"))
      );
      setFilteredDsMetros(newMetros);

      if (!props.editData || dsStates.length) {
        setDsMetros(
          dsMetros.filter((metro) =>
            dsStates.some((state) => metro.startsWith(state + "-"))
          )
        );
      }
    } else {
      setFilteredDsMetros(metros);
      if (!props.editData) {
        setDsMetros([]);
      }
    }
  }, [dsStates, props.editData]);

  const validatePremiums = (values, currentFieldName) => {
    // Check if at least one premium or one of the specified charge limits is present
    if (
      !values.flatPremium &&
      !values.percentagePremium &&
      !values.rpmPremium &&
      !values.minimumCharge &&
      !values.maximumCharge &&
      !values.rpmMin &&
      !values.rpmMax
    ) {
      form.setFieldError("flatPremium", errorMessages.noPremiumEntered);
      form.setFieldError("percentagePremium", errorMessages.noPremiumEntered);
      form.setFieldError("rpmPremium", errorMessages.noPremiumEntered);
      form.setFieldError("minimumCharge", errorMessages.noPremiumEntered);
      form.setFieldError("maximumCharge", errorMessages.noPremiumEntered);
      form.setFieldError("rpmMin", errorMessages.noPremiumEntered);
      form.setFieldError("rpmMax", errorMessages.noPremiumEntered);
      return errorMessages.noPremiumEntered;
    } else {
      // Clear errors for premiums if another field is filled
      if (currentFieldName !== "flatPremium")
        form.setFieldError("flatPremium", null);
      if (currentFieldName !== "percentagePremium")
        form.setFieldError("percentagePremium", null);
      if (currentFieldName !== "rpmPremium")
        form.setFieldError("rpmPremium", null);
      if (currentFieldName !== "minimumCharge")
        form.setFieldError("minimumCharge", null);
      if (currentFieldName !== "maximumCharge")
        form.setFieldError("maximumCharge", null);
      if (currentFieldName !== "rpmMin") form.setFieldError("rpmMin", null);
      if (currentFieldName !== "rpmMax") form.setFieldError("rpmMax", null);
    }
    return null;
  };

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      assetBrokerage: "",
      billTo: null,
      customerName: null,
      desirability: null,
      driverMode: "",
      dropoffType: undefined,
      dsDays: null,
      dsMetro: "",
      dsState: "",
      dsZip: "",
      dsZone: "",
      effectiveDate: "",
      expiryDate: "",
      flatPremium: "",
      isHazmat: false,
      maximumCharge: "",
      minimumCharge: "",
      rpmMin: "",
      rpmMax: "",
      orDays: null,
      orMetro: "",
      orState: "",
      orZip: "",
      orZone: "",
      overMiles: "",
      percentagePremium: "",
      pickupType: undefined,
      rpmPremium: "",
      ruleName: "",
      trailerMode: null,
      underMiles: "",
    },
    validate: {
      assetBrokerage: isNotEmpty(errorMessages.assetBrokerageMissing),
      effectiveDate: (value) => {
        if (!value) {
          return errorMessages.dateMissing;
        }
        return null;
      },
      expiryDate: (value, values) => {
        if (!value) {
          return errorMessages.dateMissing;
        } else if (values.effectiveDate && value < values.effectiveDate) {
          return errorMessages.effectiveDateAfterExpiryDate;
        }
        return null;
      },
      minimumCharge: (value, values) => {
        if (
          Boolean(values.maximumCharge) &&
          Boolean(value) &&
          value > values.maximumCharge
        ) {
          return errorMessages.invalidMinMax;
        }
        form.clearFieldError("maximumCharge");
        return null;
      },
      maximumCharge: (value, values) => {
        if (
          Boolean(values.minimumCharge) &&
          Boolean(value) &&
          value < values.minimumCharge
        ) {
          return errorMessages.invalidMinMax;
        }
        form.clearFieldError("minimumCharge");
        return null;
      },
      rpmMin: (value, values) => {
        if (Boolean(values.rpmMax) && Boolean(value) && value > values.rpmMax) {
          return errorMessages.invalidMinMax;
        }
        form.clearFieldError("rpmMax");
        return null;
      },
      rpmMax: (value, values) => {
        if (Boolean(values.rpmMin) && Boolean(value) && value < values.rpmMin) {
          return errorMessages.invalidMinMax;
        }
        form.clearFieldError("rpmMin");
        return null;
      },
      overMiles: (value, values) => {
        if (
          (Boolean(values.underMiles) || values.underMiles === 0) &&
          (Boolean(value) || value === 0) &&
          value >= values.underMiles
        ) {
          return errorMessages.invalidMinMax;
        } else if (!value) {
          return null; // Return null if you want to treat empty or "0" as valid and send as undefined
        }
        form.clearFieldError("underMiles");
        return null;
      },
      underMiles: (value, values) => {
        if (
          (Boolean(values.overMiles) || values.overMiles === 0) &&
          (Boolean(value) || value === 0) &&
          value <= values.overMiles
        ) {
          return errorMessages.invalidMinMax;
        } else if (!value) {
          return null; // Return null if you want to treat empty or "0" as valid and send as undefined
        }

        form.clearFieldError("overMiles");
        return null;
      },
      ruleName: isNotEmpty(errorMessages.ruleNameMissing),
      rpmPremium: (value, values) => {
        return validatePremiums(values, "rpmPremium");
      },
      percentagePremium: (value, values) => {
        return validatePremiums(values, "percentagePremium");
      },
      flatPremium: (value, values) => {
        return validatePremiums(values, "flatPremium");
      },
      minimumCharge: (value, values) => {
        return validatePremiums(values, "minimumCharge");
      },
      maximumCharge: (value, values) => {
        return validatePremiums(values, "maximumCharge");
      },
      rpmMin: (value, values) => {
        return validatePremiums(values, "rpmMin");
      },
      rpmMax: (value, values) => {
        return validatePremiums(values, "rpmMax");
      },
    },
  });

  const handleErrors = (errors) => {
    if (errors.assetBrokerage) {
      notifications.show({
        message: errorMessages.assetBrokerageMissing,
        color: "red",
      });
    } else if (errors.effectiveDate || errors.expiryDate) {
      notifications.show({
        message: "Please check that you have valid Effective and Expiry dates",
        color: "red",
      });
    } else if (errors.ruleName) {
      notifications.show({
        message: errorMessages.ruleNameMissing,
        color: "red",
      });
    } else if (
      errors.rpmPremium ||
      errors.percentagePremium ||
      errors.flatPremium
    ) {
      notifications.show({
        message: errorMessages.noPremiumEntered,
        color: "red",
      });
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const [err, setErr] = useState("");
  const [deleteBulk] = useBulkDeleteMutation();
  const [createRate] = useCreateRateMutation();
  const [updateRate] = useUpdateRateMutation();

  useEffect(() => {
    // This effect is for setting up initial values when the modal is for editing.
    if (props.editData) {
      form.setValues({
        ...getInitialValues(props.editData),
      });

      // Directly set metros from edit data without filtering
      setOrMetros(props.editData.orMetro ? props.editData.orMetro : []);
      setDsMetros(props.editData.dsMetro ? props.editData.dsMetro : []);

      // Populate states if available
      setOrStates(props.editData.orState ? props.editData.orState : []);
      setDsStates(props.editData.dsState ? props.editData.dsState : []);

      setSelectedCustomers(props.editData.customerName ?? []);
      setSelectedBillTos(props.editData.billTo ?? []);
    }
  }, [props.editData]);

  // on open revalidate form
  useEffect(() => {
    resetForm();
  }, [props.isOpen]);

  if (!props.show) {
    return null;
  }

  const deleteRow = async () => {
    setSubmitting(true);
    try {
      const { data: response } = await deleteBulk({
        ids: [props.editData.id],
        token: props.token,
      });

      if (!response.message) {
        notifications.show({ message: "An error occured", color: "red" });
      }
      notifications.show({
        message: "Deleted rate successfully.",
        color: "green",
      });
    } catch (err) {
      notifications.show({ message: err.message, color: "red" });
    } finally {
      setSubmitting(false);
    }
  };

  const formatBillToInput = (billToInput) => {
    if (billToInput) {
      if (billToInput instanceof Array) {
        return billToInput;
      } else {
        const splitArray = billToInput.split(",");
        const trimmedArray = splitArray.map((billToName) => billToName.trim());

        return trimmedArray;
      }
    }

    return [];
  };

  function getUTCDateWithoutTime(inputDate) {
    var date = new Date(inputDate);

    var year = date.getUTCFullYear();
    var month = date.getUTCMonth(); // Note: months are 0-indexed in JavaScript
    var day = date.getUTCDate();

    // Construct a UTC date string
    var utcDate = new Date(Date.UTC(year, month, day));

    return utcDate;
  }

  function getUTCMidnightEpoch(inputDate) {
    var date = new Date(inputDate);

    // Get the local time zone offset in minutes, and convert it to milliseconds
    var timezoneOffset = date.getTimezoneOffset() * 60000;

    // Subtract the time zone offset from the current time to get UTC midnight
    var utcMidnight = new Date(date.getTime() - timezoneOffset).setUTCHours(
      0,
      0,
      0,
      0
    );

    return utcMidnight;
  }

  function dataMarshall() {
    let data = {
      customerName: selectedCustomers ?? [],
      billTo: selectedBillTos ?? [],
      createdBy: props.user?.name ?? "spotAdminTool",
      updatedBy: props.user?.name ?? "spotAdminTool",
      ruleName: form.values.ruleName,
      // get utc time and set to 00:00:00 for the day
      effectiveDttm: getUTCDateWithoutTime(
        form.values.effectiveDate
      ).toISOString(),
      // get utc time and set to 00:00:00 for the day
      expirationDttm: getUTCMidnightEpoch(form.values.expiryDate).toString(),
      modes: { trailerMode: form.values.trailerMode ?? [] },
      isAsset: form.values.assetBrokerage === "asset",
      isBrokerage: form.values.assetBrokerage === "brokerage",
      isHazmat: form.isTouched("isHazmat") ? form.values.isHazmat : null,
      isTeamRequired: form.isTouched("driverMode")
        ? form.values.driverMode === "Team"
        : null,
      origin: {
        days: form.values.orDays ?? [],
        location: {
          zip: form.values.orZip
            ? form.values.orZip instanceof Array
              ? form.values.orZip
              : [form.values.orZip]
            : [],
          metro: orMetros ? orMetros : [],
          zone: form.values.orZone ? form.values.orZone : [],
          state: orStates ? orStates : [],
        },
        loadType: form.values.pickupType,
      },
      destination: {
        days: form.values.dsDays ?? [],
        location: {
          zip: form.values.dsZip
            ? form.values.dsZip instanceof Array
              ? form.values.dsZip
              : [form.values.dsZip]
            : [],
          metro: dsMetros ? dsMetros : [],
          zone: form.values.dsZone ? form.values.dsZone : [],
          state: dsStates ? dsStates : [],
        },
        loadType: form.values.dropoffType,
      },
      minimumMiles: Boolean(form.values.overMiles)
        ? form.values.overMiles
        : null,
      maximumMiles: Boolean(form.values.underMiles)
        ? form.values.underMiles
        : null,
      modifiers: {
        percentModifier: form.values?.percentagePremium?.toString().length
          ? form.values?.percentagePremium.toString()
          : null,
        flatModifier: form.values?.flatPremium?.toString().length
          ? form.values?.flatPremium.toString()
          : null,
        RPMModifier: form.values?.rpmPremium?.toString().length
          ? form.values?.rpmPremium.toString()
          : null,
        minimumCharge: form.values?.minimumCharge?.toString().length
          ? form.values?.minimumCharge.toString()
          : null,
        maximumCharge: form.values?.maximumCharge?.toString().length
          ? form.values?.maximumCharge.toString()
          : null,
        rpmMin: form.values?.rpmMin?.toString().length
          ? form.values?.rpmMin.toString()
          : null,
        rpmMax: form.values?.rpmMax?.toString().length
          ? form.values?.rpmMax.toString()
          : null,
      },
      desirabilityScoreAsset:
        form.values.assetBrokerage === "asset"
          ? form.values.desirability?.map((score) => {
              return parseInt(score);
            })
          : null,
      desirabilityScoreBrokerage:
        form.values.assetBrokerage === "brokerage"
          ? form.values.desirability?.map((score) => {
              return parseInt(score);
            })
          : null,
    };
    return data;
  }

  const handleClone = async () => {
    const formValidation = form.validate();
    if (formValidation.hasErrors) {
      handleErrors(formValidation.errors);
      return;
    }

    const payload = dataMarshall();

    const data = {
      id: props.editData.id,
      rate: {
        customerName: payload.customerName,
        billTo: payload.billTo,
        ruleName: "Copy - " + payload.ruleName,
        effectiveDttm: payload.effectiveDttm,
        expirationDttm: payload.expirationDttm,
        createdBy: payload.createdBy,
        createdDttm: payload.createdDttm,
        updatedBy: payload.updatedBy,
        modes: payload.modes,
        isAsset: payload.isAsset,
        isBrokerage: payload.isBrokerage,
        isHazmat: payload.isHazmat,
        driverMode: payload.driverMode,
        isTeamRequired: payload.isTeamRequired,
        origin: payload.origin,
        destination: payload.destination,
        minimumMiles: payload.minimumMiles,
        maximumMiles: payload.maximumMiles,
        desirabilityScoreAsset: payload.desirabilityScoreAsset,
        desirabilityScoreBrokerage: payload.desirabilityScoreBrokerage,
        modifiers: payload.modifiers,
      },
    };

    const response = await createRate({
      payload: data,
      token: props.token,
    });

    if (response?.error) {
      notifications.show({
        message: response?.error?.message ?? "An Error Has Occurred",
        color: "red",
      });
    } else {
      notifications.show({
        message: "Cloned rate successfully.",
        color: "green",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formValidation = form.validate();
    if (formValidation.hasErrors) {
      handleErrors(formValidation.errors);
      return;
    }

    const payload = dataMarshall();

    try {
      if (props.editData) {
        const data = {
          id: props.editData.id,
          rate: {
            customerName: payload.customerName,
            billTo: payload.billTo,
            ruleName: payload.ruleName,
            effectiveDttm: payload.effectiveDttm,
            expirationDttm: payload.expirationDttm,
            createdBy: payload.createdBy,
            createdDttm: payload.createdDttm,
            updatedBy: payload.updatedBy,
            modes: payload.modes,
            isAsset: payload.isAsset,
            isBrokerage: payload.isBrokerage,
            isHazmat: payload.isHazmat,
            driverMode: payload.driverMode,
            isTeamRequired: payload.isTeamRequired,
            origin: payload.origin,
            destination: payload.destination,
            minimumMiles: payload.minimumMiles,
            maximumMiles: payload.maximumMiles,
            overMiles: payload.overMiles,
            underMiles: payload.underMiles,
            desirabilityScoreAsset: payload.desirabilityScoreAsset,
            desirabilityScoreBrokerage: payload.desirabilityScoreBrokerage,
            modifiers: payload.modifiers,
          },
        };

        const response = await updateRate({
          payload: data,
          token: props.token,
        });

        if (response?.error) {
          notifications.show({
            message: response?.error?.message ?? "An Error Has Occurred",
            color: "red",
          });
        } else {
          notifications.show({
            message: "Updated rate successfully.",
            color: "green",
          });
        }
      } else {
        const data = {
          rate: {
            customerName: payload.customerName,
            billTo: payload.billTo,
            ruleName: payload.ruleName,
            effectiveDttm: payload.effectiveDttm,
            expirationDttm: payload.expirationDttm,
            createdBy: payload.createdBy,
            createdDttm: payload.createdDttm,
            modes: payload.modes,
            isAsset: payload.isAsset,
            isBrokerage: payload.isBrokerage,
            isHazmat: payload.isHazmat,
            driverMode: payload.driverMode,
            isTeamRequired: payload.isTeamRequired,
            origin: payload.origin,
            destination: payload.destination,
            minimumMiles: payload.minimumMiles,
            maximumMiles: payload.maximumMiles,
            overMiles: payload.overMiles,
            underMiles: payload.underMiles,
            desirabilityScoreAsset: payload.desirabilityScoreAsset,
            desirabilityScoreBrokerage: payload.desirabilityScoreBrokerage,
            modifiers: payload.modifiers,
          },
        };
        const response = await createRate({
          payload: data,
          token: props.token,
        });

        if (response?.error) {
          notifications.show({
            message: response?.error?.message ?? "An Error Has Occurred",
            color: "red",
          });
        } else {
          notifications.show({
            message: "Created rate successfully.",
            color: "green",
          });
        }
      }
    } catch (err) {
      setErr(err.message);
    } finally {
      if (!err) {
        props.onDataUpdate();
        resetForm();
        props.onClose();
      }
    }
  };

  function resetForm() {
    // Clear any existing errors
    setErr();

    // Reset the form fields and validate rpmPremium
    form.reset();
    form.validateField("rpmPremium");

    // Clear the state for multi-selects
    setOrStates([]);
    setDsStates([]);
    setOrMetros([]);
    setDsMetros([]);
    setSelectedCustomers([]);
    setSelectedBillTos([]);

    // Reset filtered metros if you want
    setFilteredOrMetros(metros);
    setFilteredDsMetros(metros);
  }

  return (
    <Modal
      opened={true}
      title={props.editData ? "Edit Form" : "Input Form"}
      onClose={() => {
        resetForm();
        props.onClose();
      }}
      centered
      className="modal"
      size="90%"
    >
      <div className="rate-modal-content">
        {submitting && (
          <div>
            <img src={loadingIcon} />
          </div>
        )}
        <div className="rate-modal-form-container">
          <form onSubmit={handleSubmit} id="entryForm" onKeyDown={onKeyDown}>
            <div className="grid-container">
              <div className="nested-row">
                <fieldset>
                  <Select
                    allowDeselect={false}
                    clearable
                    withAsterisk
                    label="Asset/Brokerage"
                    id="assetBrokerage"
                    data={[
                      { value: "asset", label: "Asset" },
                      { value: "brokerage", label: "Brokerage" },
                    ]}
                    {...form.getInputProps("assetBrokerage")}
                  />
                </fieldset>
                <fieldset>
                  <TextInput
                    withAsterisk
                    label="Rule Name"
                    id="ruleName"
                    {...form.getInputProps("ruleName")}
                  />
                </fieldset>
                <fieldset>
                  <DateInput
                    withAsterisk
                    valueFormat="MM/DD/YYYY"
                    label="Effective Date"
                    id="effectiveDate"
                    {...form.getInputProps("effectiveDate", { type: "date" })}
                  />
                </fieldset>
                <fieldset>
                  <DateInput
                    withAsterisk
                    valueFormat="MM/DD/YYYY"
                    label="Expiry Date"
                    id="expiryDate"
                    {...form.getInputProps("expiryDate", { type: "date" })}
                  />
                </fieldset>
              </div>
              <div className="nested-row">
                <fieldset>
                  <MultiSelect
                    label="OR (days)"
                    id="orDays"
                    data={days.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("orDays")}
                  />
                </fieldset>
                <fieldset>
                  <TextInput
                    label="OR (zip)"
                    id="orZip"
                    {...form.getInputProps("orZip")}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    clearable
                    label="OR (state)"
                    id="orState"
                    multiple
                    data={stateCodes
                      .sort()
                      .map((option) => ({ value: option, label: option }))}
                    value={orStates}
                    onChange={(value) => setOrStates(value)}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    label="OR (metro)"
                    id="orMetro"
                    data={filteredOrMetros
                      .sort()
                      .map((option) => ({ value: option, label: option }))}
                    value={orMetros}
                    onChange={(value) => setOrMetros(value)}
                  />
                </fieldset>
                {/* <fieldset>
                  <MultiSelect
                    clearable
                    label="OR (zones)"
                    id="orZone"
                    data={zones.sort().map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("orZone")}
                  />
                </fieldset> */}
              </div>
              <div className="nested-row">
                <fieldset>
                  <MultiSelect
                    label="DS (days)"
                    id="dsDays"
                    data={days.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("dsDays")}
                  />
                </fieldset>
                <fieldset>
                  <TextInput
                    label="DS (zip)"
                    id="dsZip"
                    {...form.getInputProps("dsZip")}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    clearable
                    label="DS (state)"
                    id="dsState"
                    multiple
                    data={stateCodes
                      .sort()
                      .map((option) => ({ value: option, label: option }))}
                    value={dsStates}
                    onChange={(value) => setDsStates(value)}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    label="DS (metro)"
                    id="dsMetro"
                    data={filteredDsMetros
                      .sort()
                      .map((option) => ({ value: option, label: option }))}
                    value={dsMetros}
                    onChange={(value) => setDsMetros(value)}
                  />
                </fieldset>
                {/* <fieldset>
                  <MultiSelect
                    clearable
                    label="DS (zones)"
                    id="dsZone"
                    data={zones.sort().map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("dsZone")}
                  />
                </fieldset> */}
              </div>
              <div className="nested-row">
                <fieldset>
                  <MultiSelect
                    label="Select Customers"
                    placeholder="Select customers"
                    data={customerOptions}
                    value={selectedCustomers}
                    onChange={setSelectedCustomers}
                    searchable
                    clearable
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    label="BillTo Numbers"
                    placeholder="Select BillTo numbers"
                    data={getBillToOptions()}
                    value={selectedBillTos}
                    onChange={setSelectedBillTos}
                    disabled={!selectedCustomers.length}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    id="desirability"
                    label="Desirability"
                    multiple
                    data={desirabilityOptions.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("desirability")}
                  />
                </fieldset>
                <fieldset>
                  <Select
                    allowDeselect={false}
                    clearable
                    label="Pickup Type"
                    id="pickupType"
                    data={pickDropOptions.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("pickupType")}
                  />
                </fieldset>
                <fieldset>
                  <Select
                    allowDeselect={false}
                    clearable
                    label="Dropoff Type"
                    id="dropoffType"
                    data={pickDropOptions.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("dropoffType")}
                  />
                </fieldset>
              </div>
              <div className="nested-row">
                <fieldset>
                  <Select
                    allowDeselect={false}
                    clearable
                    label="Driver Mode"
                    id="driverMode"
                    data={driverModeOptions.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("driverMode")}
                  />
                </fieldset>
                <fieldset>
                  <MultiSelect
                    label="Trailer Mode"
                    id="trailerMode"
                    data={trailerModes.map((option, index) => {
                      return {
                        value: option,
                        label: option,
                      };
                    })}
                    {...form.getInputProps("trailerMode")}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="Over miles"
                    id="overMiles"
                    min={0}
                    {...form.getInputProps("overMiles", { type: "number" })}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="Under miles"
                    id="underMiles"
                    min={0}
                    {...form.getInputProps("underMiles", { type: "number" })}
                  />
                </fieldset>
                <Checkbox
                  className="checkbox"
                  label="Is Hazmat"
                  id="isHazmat"
                  {...form.getInputProps("isHazmat", { type: "checkbox" })}
                />
              </div>
              <div className="nested-row">
                <fieldset>
                  <NumberInput
                    label="Flat Premium"
                    id="flatPremium"
                    precision={2}
                    {...form.getInputProps("flatPremium", { type: "number" })}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="Percentage Premium"
                    id="percentagePremium"
                    precision={2}
                    {...form.getInputProps("percentagePremium", {
                      type: "number",
                    })}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="RPM Premium"
                    id="rpmPremium"
                    precision={2}
                    {...form.getInputProps("rpmPremium", { type: "number" })}
                  />
                </fieldset>
              </div>
              <div className="nested-row">
                <fieldset>
                  <NumberInput
                    label="Linehaul min"
                    id="minimumCharge"
                    min={0}
                    precision={2}
                    {...form.getInputProps("minimumCharge")}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="Linehaul max"
                    id="maximumCharge"
                    min={0}
                    precision={2}
                    {...form.getInputProps("maximumCharge")}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="RPM min"
                    id="rpmMin"
                    min={0}
                    precision={2}
                    {...form.getInputProps("rpmMin")}
                  />
                </fieldset>
                <fieldset>
                  <NumberInput
                    label="RPM max"
                    id="rpmMax"
                    min={0}
                    precision={2}
                    {...form.getInputProps("rpmMax")}
                  />
                </fieldset>
              </div>
            </div>
            <small className="errors"></small>
            <Group position="center">
              {props.editData && (
                <Button
                  color="green"
                  name="clone-button"
                  onClick={() => {
                    handleClone();
                    resetForm();
                    props.onClose();
                  }}
                >
                  Clone
                </Button>
              )}
              {props.editData && (
                <Button
                  color="red"
                  id="delete"
                  onClick={() => {
                    deleteRow();
                    resetForm();
                    props.onClose();
                  }}
                >
                  Delete
                </Button>
              )}
              <Button
                color="gray"
                id="close"
                onClick={() => {
                  resetForm();
                  props.onClose();
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                id="submit"
                disabled={form.errors && Object.keys(form.errors).length > 0}
              >
                Submit
              </Button>
            </Group>
          </form>
          {submitting && (
            <div>
              <img src={loadingIcon} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
