import React from "react";
import { Button, Group, Modal} from '@mantine/core';

export const ConfirmModal = (props) => {

  if (!props.show) {
    return null;
  }

  return (
    <>
      <Modal opened={true} title={props.message} withCloseButton={false} centered className="modal">
        <Group position="center" mt="md">
          <Button id="cancel" onClick={() => {
              props.onClose(false);
            }}>Cancel</Button>
          <Button id="submit" onClick={() => {
              props.onClose(true);
            }}>Submit</Button>
        </Group>
      </Modal>
    </>
  );
};
