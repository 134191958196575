export const CREATE_RATE = `
    query createSuggestedRate($rate: SuggestedRateInput) {
        createSuggestedRate(rate: $rate) {
            createdBy
            createdDttm
        }
    }
    `;

export const BULK_DELETE = `
    query batchDeleteSuggestedRates($payload: SuggestedRateBatchDeletePayload) {
        batchDeleteSuggestedRates(payload: $payload) {
            message
        }
    }
`;

export const BULK_DEACTIVATE = `
    query batchDeactivateSuggestedRates($payload: SuggestedRateBatchDeactivatePayload) {
        batchDeactivateSuggestedRates(payload: $payload) {
            message
        }
    }
`;

export const UPDATE_RATE = `
    query updateSuggestedRate($id: String, $rate: SuggestedRateInput) {
        updateSuggestedRate(id: $id, rate: $rate) {
            updatedBy
            updatedDttm
        }
    }
`;

export const DELETE_RATE = `
    query deleteSuggestedRate($id: String) {
        deleteSuggestedRate(id: $id) {
            message
        }
    }  
`;

// TODO: USE THIS TO MANAGE LOGGING IN
export const LOGIN = `
    mutation login($email: String, $password: String, $audience: String, ) {
        login(email: $email, password: $password, audience: $audience) {
            user {
                id
                name
                email
                role
            }
            refreshToken
            expiresAt
        }
    }
`;
