export const stateCodes = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const desirabilityOptions = [1, 2, 3, 4, 5];
export const driverModeOptions = ["Team", "Solo"];
export const trailerModes = ["VAN", "FLAT", "LBOY", "REEF", "DRPD"];
export const pickDropOptions = ["live", "drop"];
export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const multiSelectFields = [
  "orZone",
  "dsZone",
  "orMetro",
  "dsMetro",
  "customerName",
  "orDays",
  "dsDays",
  "desirability",
  "trailerMode",
];
export const customerNames = [
  {
    customerName: "Anheuser-Busch",
    billTo: [1267861, 1269720],
  },
  {
    customerName: "BLUETRITON BRANDS",
    billTo: [1298629],
  },
  {
    customerName: "CAMPBELL SOUP",
    billTo: [1305754],
  },
  {
    customerName: "CAMPBELL SOUP COMPANY (CSC) SNACKS",
    billTo: [1347770],
  },
  {
    customerName: "Chewy",
    billTo: [1290652],
  },
  {
    customerName: "CHRobinson",
    billTo: [1327981],
  },
  {
    customerName: "CHURCH & DWIGHT",
    billTo: [1325534, 1325530],
  },
  {
    customerName: "CHURCH & DWIGHT-USXF",
    billTo: [1368543],
  },
  {
    customerName: "Clorox",
    billTo: [1304636, 602826],
  },
  {
    customerName: "COLGATE-PALMOLIVE",
    billTo: [1162529],
  },
  {
    customerName: "Coyote",
    billTo: [1347391],
  },
  {
    customerName: "DollarGeneral",
    billTo: [1297898, 1297900],
  },
  {
    customerName: "HomeDepotAPI",
    billTo: [1348252, 1348253],
  },
  {
    customerName: "HomeDepot-UHR",
    billTo: [699590],
  },
  {
    customerName: "KOHLS DEPARTMENT STORES INC",
    billTo: [1369068],
  },
  {
    customerName: "KraftHeinz",
    billTo: [1323132, 1323131],
  },
  {
    customerName: "Macys",
    billTo: [1338557, 1338559],
  },
  {
    customerName: "Michaels",
    billTo: [1336651, 1336649],
  },
  {
    customerName: "Monsanto",
    billTo: [1036283, 1036283],
  },
  {
    customerName: "PGNA",
    billTo: [1275043, 1044742],
  },
  {
    customerName: "REFRESCO BEVERAGES-USXF",
    billTo: [1315865],
  },
  {
    customerName: "REFRESCOBEVERAGES",
    billTo: [1315865],
  },
  {
    customerName: "TJX",
    billTo: [1320117, 1320120],
  },
  {
    customerName: "TRUE VALUE",
    billTo: [1373000],
  },
  {
    customerName: "Uber",
    billTo: [1343207],
  },
  {
    customerName: "Walmart",
    billTo: [1292291],
  },
  {
    customerName: "Westrock",
    billTo: [1329049, 1285912],
  },
  {
    customerName: "McCormick",
    billTo: [1375498, 1375499],
  },
  {
    customerName: "Kik",
    billTo: [1378856],
  },
  // {
  //   customerName: "TGTU",
  //   billTo: [],
  // },
  {
    customerName: "NEOSHO",
    billTo: [1375630],
  },
  {
    customerName: "NWL",
    billTo: [1375630],
  },
  {
    customerName: "EMA",
    billTo: [1375784],
  },
  {
    customerName: "TLF",
    billTo: [1375633],
  },
  {
    customerName: "PNG",
    billTo: [1378046],
  },
  {
    customerName: "MOT",
    billTo: [1376961],
  },
  {
    customerName: "ABC",
    billTo: [1376951],
  },
  {
    customerName: "KGM",
    billTo: [1375786],
  },
  {
    customerName: "DPS",
    billTo: [1376959],
  },
  {
    customerName: "PBR",
    billTo: [1375634],
  },
  {
    customerName: "Kimberly Clark",
    billTo: [1256031, 1379183],
  },
  {
    customerName: "Tosca",
    billTo: [1379933],
  },
  {
    customerName: "Blain Supply",
    billTo: [1376093],
  },
];
