import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ActionItemCellRender } from "./ActionItemCellRenderer";
import { SelectAllHeader } from "./SelectAllHeader";
import CheckboxSelectFilter from "./headerComponents/selectFilterComponent/SelectFilter";
import { TextInput } from "@mantine/core";

export function AGTable({
  data,
  selectRow,
  selectedRows,
  selectRowsBulk,
  editData,
  colsToShow,
  setGridApi,
  onGetAllDisplayedColumns,
}) {
  const gridRef = useRef();
  const gridApi = useRef();

  // cols to show state
  const [colsToShowState, setColsToShowState] = useState(colsToShow);
  const [filterText, setFilterText] = useState("");

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      floatingFilter: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  const rowData = useMemo(() => {
    return data.filter((item) => !selectedRows.includes(item.id));
  }, [data, selectedRows]);

  // This useEffect is used to restore the column state from local storage
  useEffect(() => {
    const savedState = localStorage.getItem("columnState");

    if (savedState) {
      // make sure colsToShowState is in the same order as savedState
      const columnState = JSON.parse(savedState) || [];
      const updatedColsToShowState = [...colsToShow];
      updatedColsToShowState.sort((a, b) => {
        let indexA = columnState.findIndex((col) => col.colId === a.field);
        let indexB = columnState.findIndex((col) => col.colId === b.field);
        return indexA - indexB;
      });

      // Update the hidden columns in colsToShowState
      const updatedColsToShowStateWithHidden = updatedColsToShowState.map(
        (col) => {
          const savedCol = columnState.find((c) => c.colId === col.field);
          return savedCol.hide ? { ...col, hide: true } : col;
        }
      );

      setColsToShowState(updatedColsToShowStateWithHidden);
    } else {
      setColsToShowState(colsToShow);
    }
  }, [colsToShow, rowData]);

  useEffect(() => {
    updatePinnedRows();
  }, [data, selectedRows]);

  const updatePinnedRows = () => {
    if (gridApi.current) {
      const pinnedRows = data.filter((item) => selectedRows.includes(item.id));
      gridApi.current.setPinnedBottomRowData(pinnedRows);
    }
  };

  const userColumnInteraction = useRef(false);

  const saveColumnState = (event) => {
    if (event.finished && userColumnInteraction.current) {
      const columnState = gridApi.current.getColumnState();
      localStorage.setItem("columnState", JSON.stringify(columnState));
      userColumnInteraction.current = false; // Reset flag
    } else if (!event.finished && !userColumnInteraction.current) {
      userColumnInteraction.current = true;
    }
  };

  const restoreColumnState = () => {
    const savedState = localStorage.getItem("columnState");
    if (savedState && gridApi.current) {
      gridApi.current.applyColumnState({
        state: JSON.parse(savedState),
        applyOrder: true,
      });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridRef.current = params.columnApi;
    gridApi.current = params.api;
    updatePinnedRows();

    restoreColumnState();
  };

  const getAllDisplayedColumns = () => {
    return gridRef.current
      .getAllDisplayedColumns()
      .map((col) => col.getColDef().field);
  };

  useEffect(() => {
    if (onGetAllDisplayedColumns) {
      onGetAllDisplayedColumns(getAllDisplayedColumns);
    }
  }, []);

  const components = useMemo(() => {
    return {
      checkboxSelectFilter: CheckboxSelectFilter,
    };
  }, []);

  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ width: "99vw", height: "85vh", margin: "auto" }}
    >
      <div className="example-header">
        <TextInput
          size="xs"
          radius="xl"
          label="Quick filter"
          placeholder="Search across all columns.."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ marginBottom: "10px", maxWidth: "300px" }}
        />
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        components={components}
        columnDefs={[
          {
            field: "actionItems",
            headerName: "",
            filter: false,
            cellRenderer: ActionItemCellRender,
            headerComponent: SelectAllHeader,
            cellRendererParams: {
              selectRow: selectRow,
              editData: editData,
              selectedRows: selectedRows,
            },
            headerComponentParams: {
              selectRowsBulk: selectRowsBulk,
              selectedRows: selectedRows,
            },
            sortable: false,
          },
          ...colsToShowState,
        ]}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationAutoPageSize={true}
        suppressRowClickSelection={true}
        suppressCellFocus={true}
        rowSelection="multiple"
        onGridReady={onGridReady}
        floatingFiltersHeight={60}
        suppressColumnVirtualisation={true}
        onColumnMoved={(event) => {
          saveColumnState(event);
        }}
        quickFilterText={filterText}
      />
    </div>
  );
}
