import DateRangeFloatingFilter from "components/tables/AGTable/DateRangeFloatingFilter";
import CheckboxSelectFilter from "components/tables/AGTable/headerComponents/selectFilterComponent/SelectFilter";

const dateFunction = (filterLocalDateAtMidnight, cellValue) => {
  const cellDateValue = new Date(cellValue);
  const cellDate = new Date(
    cellDateValue.getFullYear(),
    cellDateValue.getMonth(),
    cellDateValue.getDate()
  );

  const filterDate = new Date(
    filterLocalDateAtMidnight.getFullYear(),
    filterLocalDateAtMidnight.getMonth(),
    filterLocalDateAtMidnight.getDate()
  );

  if (cellDate < filterDate) {
    return -1;
  } else if (cellDate > filterDate) {
    return 1;
  } else {
    return 0;
  }
};

const parseCellValue = (cellValue) => {
  if (Array.isArray(cellValue)) {
    return cellValue.map(Number); // Assuming cellValue array contains numeric values or numeric strings
  } else if (typeof cellValue === "string") {
    return cellValue
      .split(",")
      .filter((v) => v.trim() !== "")
      .map(Number);
  } else {
    return [Number(cellValue)];
  }
};

const predicates = [
  {
    displayKey: "customEquals",
    displayName: "Equals",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => Number(v) === filterValue);
    },
  },
  {
    displayKey: "customNotEqual",
    displayName: "Not Equal",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => v !== filterValue);
    },
  },
  {
    displayKey: "customLessThan",
    displayName: "Less Than",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => v < filterValue);
    },
  },
  {
    displayKey: "customLessThanOrEqual",
    displayName: "Less Than or Equal",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => v <= filterValue);
    },
  },
  {
    displayKey: "customGreaterThan",
    displayName: "Greater Than",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => v > filterValue);
    },
  },
  {
    displayKey: "customGreaterThanOrEqual",
    displayName: "Greater Than or Equal",
    predicate: ([filterValue], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => v >= filterValue);
    },
  },
  {
    displayKey: "customInRange",
    displayName: "In Range",
    predicate: ([fv1, fv2], cellValue) => {
      const cellValueArray = parseCellValue(cellValue);
      return cellValueArray.some((v) => fv1 <= v && v <= fv2);
    },
    numberOfInputs: 2,
  },

  // Add other custom filters as needed
];

const zipFilterParams = {
  filterOptions: [...predicates, "blank", "notBlank"],
  maxNumConditions: 999,
};

const checkboxFilterConfig = {
  filter: true,
  headerComponent: "checkboxSelectFilter",
  headerComponentParams: {
    filterIcon: true,
  },
  filterParams: {
    maxNumConditions: 999,
  },
};

export const defaultColumnConfig = [
  {
    field: "isAsset",
    headerName: "Asset/Brokerage",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "ruleName",
    headerName: "Rule Name",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "billTo",
    headerName: "Bill To",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "desirability",
    headerName: "Desirability",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "trailerMode",
    headerName: "Trailer Mode",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "loadType",
    headerName: "Load Type",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "unloadType",
    headerName: "Unload Type",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "hazmat",
    headerName: "HAZMAT",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "driverMode",
    headerName: "Driver Mode",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "effectiveDttm",
    headerName: "Effective Date/Time",
    floatingFilterComponent: DateRangeFloatingFilter,
    floatingFilterComponentParams: {
      comparator: dateFunction,
    },
    hide: false,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: dateFunction,
      inRangeInclusive: true,
      defaultOption: "inRange",
      browserDatePicker: true,
    },
  },
  {
    field: "expirationDttm",
    headerName: "Expiration Date/Time",
    hide: false,
    filter: "agDateColumnFilter",
    floatingFilterComponent: DateRangeFloatingFilter,
    floatingFilterComponentParams: {
      comparator: dateFunction,
    },
    filterParams: {
      comparator: dateFunction,
      inRangeInclusive: true,
      defaultOption: "inRange",
      browserDatePicker: true,
    },
  },
  {
    field: "orDays",
    headerName: "Origin Days",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "orMetro",
    headerName: "Origin Metro",
    hide: false,
    ...checkboxFilterConfig,
  },
  // {
  //   field: "orZones",
  //   headerName: "Origin Zones",
  //   hide: false,
  //   ...checkboxFilterConfig,
  // },
  {
    field: "orState",
    headerName: "Origin State",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "orZip",
    headerName: "Origin ZIP",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
      ...zipFilterParams,
    },
  },
  {
    field: "dsDays",
    headerName: "Destination Days",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "dsMetro",
    headerName: "Destination Metro",
    hide: false,
    ...checkboxFilterConfig,
  },
  // {
  //   field: "dsZones",
  //   headerName: "Destination Zones",
  //   hide: false,
  //   ...checkboxFilterConfig,
  // },
  {
    field: "dsState",
    headerName: "Destination State",
    hide: false,
    ...checkboxFilterConfig,
  },
  {
    field: "dsZip",
    headerName: "Destination ZIP",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
      ...zipFilterParams,
    },
  },
  {
    field: "overMiles",
    headerName: "Over Miles",
    hide: false,
    filter: "agNumberColumnFilter",
    floatingFilter: true,
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "underMiles",
    headerName: "Under Miles",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "percentModifier",
    headerName: "Percent Modifier",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "flat",
    headerName: "Flat",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "RPM",
    headerName: "RPM",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "minimumCharge",
    headerName: "Min Linehaul",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "maximumCharge",
    headerName: "Max Linehaul",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "rpmMin",
    headerName: "Min RPM",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "rpmMax",
    headerName: "Max RPM",
    hide: false,
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    field: "updatedBy",
    headerName: "Updated By",
    hide: false,
    ...checkboxFilterConfig,
  },
];
