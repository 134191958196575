import { TextInput, Button, Group, Modal, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import { useLoginQuery } from "../../../Store/api";

export function LoginModal(props) {
  const [err, setErr] = useState("");
  const [skip, setSkip] = useState(true);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const { data, isLoading, error } = useLoginQuery(
    { email: form.values.email, password: form.values.password },
    { skip }
  );

  useEffect(() => {
    if (!skip) {
      if (data && data.isLoggedIn) {
        setSkip(true);
        props.setIsLoggedIn(true);
        props.setUser(data.user);
        props.setToken(data.accessToken);
      } else if (error) {
        setErr("Invalid email or password");
        setSkip(true);
      }
    }
  }, [data, error, skip, props]);

  function onSubmit(values) {
    setSkip(false);
  }

  return (
    <>
      <Modal
        opened={true}
        title="Authentication"
        centered
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
        className="modal"
      >
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            withAsterisk
            label="Email"
            placeholder="your@email.com"
            id="email"
            {...form.getInputProps("email")}
          />
          <TextInput
            type="password"
            withAsterisk
            label="Password"
            placeholder="**********"
            id="password"
            {...form.getInputProps("password")}
          />

          {err && (
            <Text color={"red"} weight={500} size="15px">
              {err}
            </Text>
          )}

          <Group position="right" mt="md">
            <Button type="submit" id="submit">
              Submit
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
}
