import { createApi } from "@reduxjs/toolkit/query/react";
import { fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BULK_DEACTIVATE,
  CREATE_RATE,
  UPDATE_RATE,
  LOGIN,
} from "./gql/mutations/mutations";
import {
  GET_ALL_SUGGESTED_RATES,
  GET_FEATURE_FLAGS,
} from "./gql/queries/queries";
import postRequest from "../utils/helpers/post";

export const ratesApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ["User", "Rates", "Rate", "Auth", "AllRates"],
  endpoints: (builder) => ({
    login: builder.query({
      async queryFn(args) {
        const { email, password } = args ?? {};

        if (!email || !password) {
          return { data: { isLoggedIn: false } };
        }
        try {
          console.log("login");
          console.log(process.env.REACT_APP_LOGIN_ENDPOINT, {
            email,
            password,
          });
          const loginResponse = await postRequest(
            process.env.REACT_APP_LOGIN_ENDPOINT,
            {
              email,
              password,
            }
          );

          if (!loginResponse.user.broker) {
            throw new Error("User does not have the correct permissions");
          }

          const environments =
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? ["production", "pilot"]
              : [process.env.REACT_APP_ENVIRONMENT];
          const featureFlagsResponses = await Promise.all(
            environments.map((env) =>
              postRequest(
                process.env.REACT_APP_FEATURE_FLAG_ENDPOINT,
                {
                  query: GET_FEATURE_FLAGS,
                  variables: { environment: env, platform: "web" },
                },
                loginResponse.accessToken
              )
            )
          );

          const hasAccess = featureFlagsResponses.some((response) =>
            response.data.getFeatureFlags
              .find((flag) => flag.name === "SPOT_ADMIN_TOOL")
              ?.permissions[0]?.users?.includes(loginResponse.user.id)
          );

          if (!hasAccess) {
            throw new Error(
              "User does not have the correct permissions for the feature flag"
            );
          }

          return {
            data: {
              isLoggedIn: true,
              user: loginResponse.user,
              accessToken: loginResponse.accessToken,
            },
          };
        } catch (err) {
          return {
            error: {
              status: "API_ERROR",
              error: err.message || "An unknown error occurred",
            },
          };
        }
      },
      providesTags: ["User", "Auth"],
      invalidatesTags: ["User", "Auth"],
    }),
    getRates: builder.query({
      async queryFn({ active = 0, limit, skip, token }) {
        try {
          const response = await fetch(process.env.REACT_APP_GET_ENDPOINT, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: GET_ALL_SUGGESTED_RATES,
              variables: { active, limit, skip },
            }),
          });
          if (!response.ok) {
            throw new Error(`An error has occured: ${response.statusText}`);
          }

          const data = (await response.json())?.data?.getSuggestedRates;
          return { data: data };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: ["Rates"],
    }),
    getAllRates: builder.query({
      async queryFn({ active = 0, limit, skip, token }) {
        try {
          const response = await fetch(process.env.REACT_APP_GET_ENDPOINT, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: GET_ALL_SUGGESTED_RATES,
              variables: { active, limit, skip },
            }),
          });
          if (!response.ok) {
            throw new Error(`An error has occurred: ${response.statusText}`);
          }

          const data = (await response.json())?.data?.getSuggestedRates;
          return { data: data };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: ["AllRates"],
    }),
    bulkDelete: builder.mutation({
      async queryFn({ ids, token }) {
        const response = await fetch(process.env.REACT_APP_GET_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: BULK_DEACTIVATE,
            variables: {
              payload: {
                ids,
              },
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`An error has occured: ${response.statusText}`);
        }

        return {
          data: (await response.json())?.data?.batchDeactivateSuggestedRates,
        };
      },
      invalidatesTags: ["Rates"],
    }),
    createRate: builder.mutation({
      async queryFn({ payload, token }) {
        const response = await fetch(process.env.REACT_APP_GET_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: CREATE_RATE,
            variables: {
              ...payload,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`An error has occured: ${response.statusText}`);
        }

        return {
          data: (await response.json())?.data?.createSuggestedRate,
        };
      },
      invalidatesTags: ["Rates"],
    }),
    updateRate: builder.mutation({
      async queryFn({ payload, token }) {
        const response = await fetch(process.env.REACT_APP_GET_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: UPDATE_RATE,
            variables: {
              ...payload,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`An error has occured: ${response.statusText}`);
        }

        return {
          data: (await response.json())?.data?.createSuggestedRate,
        };
      },
      invalidatesTags: ["Rates"],
    }),
  }),
});

// Export the hooks for the endpoints
export const {
  useGetRatesQuery, // For active rates
  useGetAllRatesQuery, // For inactive and active rates combined
  useLoginQuery,
  useBulkDeleteMutation,
  useCreateRateMutation,
  useUpdateRateMutation,
} = ratesApi;
