import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { Portal } from "@mantine/core";

const DateRangeFloatingFilter = forwardRef(
  ({ parentFilterInstance, currentParentModel }, ref) => {
    const [value, setValue] = useState([null, null]);

    useEffect(() => {
      if (currentParentModel) {
        setValue([
          currentParentModel.dateFrom
            ? dayjs(currentParentModel.dateFrom, "DD/MM/YYYY").toDate()
            : null,
          currentParentModel.dateTo
            ? dayjs(currentParentModel.dateTo, "DD/MM/YYYY").toDate()
            : null,
        ]);
      }
    }, [currentParentModel]);

    useImperativeHandle(ref, () => ({
      onParentModelChanged(model) {
        if (!model) {
          setValue([null, null]);
          return;
        }

        // When using combo filters, model can have multiple conditions
        if (model.conditions) {
          const condition = model.conditions.find(
            (cond) => cond.type === "inRange"
          );
          if (condition) {
            setValue([
              dayjs(condition.dateFrom, "DD/MM/YYYY").toDate() || null,
              dayjs(condition.dateTo, "DD/MM/YYYY").toDate() || null,
            ]);
          }
        } else {
          setValue([
            dayjs(model.dateFrom, "DD/MM/YYYY").toDate() || null,
            dayjs(model.dateTo, "DD/MM/YYYY").toDate() || null,
          ]);
        }
      },
    }));

    const notifyDateChange = (newStartDate, newEndDate) => {
      parentFilterInstance((instance) => {
        const start = newStartDate ? newStartDate : null;
        const end = newEndDate ? newEndDate : null;

        // Set the model for the range filter
        const model = {
          type: "inRange",
          dateFrom: start,
          dateTo: end,
        };

        instance.setModelIntoUi(model);
        instance.onUiChanged(true);
      });
    };

    const onDateChange = (dates) => {
      // Format the dates to YYYY-MM-DD
      const formattedStartDate = dates[0]
        ? dates[0].toISOString().split("T")[0]
        : null;
      const formattedEndDate = dates[1]
        ? dates[1].toISOString().split("T")[0]
        : null;

      setValue(dates);
      notifyDateChange(formattedStartDate, formattedEndDate);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
        }}
      >
        <DatePickerInput
          placeholder="MM/DD/YY"
          value={value}
          type="range"
          allowSingleDateInRange={true}
          size="xs"
          clearable
          valueFormat="MM/DD/YY"
          onChange={(e) => onDateChange(e)}
          style={{ maxWidth: "150px", width: "100%" }}
          popoverProps={{ withinPortal: true }}
          styles={{
            input: {
              backgroundColor: "#181d1f",
              borderColor: "#68686e",
            },
          }}
        />
      </div>
    );
  }
);

export default DateRangeFloatingFilter;
