export default async function postRequest(url, body, token) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "", // This sets Authorization to an empty string if token is undefined or empty
    };

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ ...body }),
    });

    if (!response.ok) {
      throw new Error(response.statusText || "Network response was not ok");
    }

    return response.json();
  } catch {
    return;
  }
}
