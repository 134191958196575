import { useState } from "react";

export function useSelectionState() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showBulkDeleteIcon, setShowBulkDeleteIcon] = useState(false);

  const selectRow = (rowId) => {
    const index = selectedRows.findIndex((id) => id === rowId);

    if (index >= 0) {
      // Remove the rowId from the array
      const updatedRows = [
        ...selectedRows.slice(0, index),
        ...selectedRows.slice(index + 1),
      ];
      setSelectedRows(updatedRows);
      if (updatedRows.length === 0) {
        setShowBulkDeleteIcon(false);
      }
    } else {
      // Add the rowId to the array
      const updatedRows = [...selectedRows, rowId];
      setSelectedRows(updatedRows);
      setShowBulkDeleteIcon(true);
    }
  };

  const selectRowsBulk = (rows) => {
    if (rows.length === 0) {
      setSelectedRows([]);
      setShowBulkDeleteIcon(false);
      return;
    }
    setSelectedRows(rows);
    setShowBulkDeleteIcon(true);
  };
  return {
    selectedRows,
    setSelectedRows,
    selectRowsBulk,
    showBulkDeleteIcon,
    setShowBulkDeleteIcon,
    selectRow,
  };
}
