import { useGetRatesQuery, useGetAllRatesQuery } from "../../Store/api";
import { useState } from "react";

export function useRatesData(token) {
  const [fetchError, setFetchError] = useState(null);
  const {
    data: ratesData,
    isLoading: ratesLoading,
    refetch: fetchRates,
  } = useGetRatesQuery({ active: 1, skip: 0, token }, { skip: !token });

  const {
    data: allRatesData,
    isLoading: allRatesLoading,
    refetch: fetchAllRates,
  } = useGetAllRatesQuery({ active: 0, skip: 0, token }, { skip: !token });

  const fetchData = async () => {
    try {
      await fetchRates();
    } catch (error) {
      setFetchError(error);
    }
  };

  return {
    ratesData,
    ratesLoading,
    allRatesData,
    allRatesLoading,
    fetchData,
    fetchAllRates,
    fetchError,
  };
}
