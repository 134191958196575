import React, { useEffect, useRef, useState } from "react";
import { Popover, Checkbox, TextInput, Portal, Button } from "@mantine/core";
import "./SelectFilter.scss";

const CheckboxSelectFilter = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortState, setSortState] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [uniqueValues, setUniqueValues] = useState([]);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const refButton = useRef(null);

  // Function to recalculate unique values based on the current filter model
  const recalculateUniqueValues = (bypass = false) => {
    if (!popoverOpened || bypass) {
      let allValues = [
        { value: "All", type: "standard" },
        { value: "Blank", type: "standard" },
      ];

      props.api.forEachNodeAfterFilter((node) => {
        const value = node.data[props.column.colId];

        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item && item.toString().trim() !== "") {
              if (!allValues.some((val) => val.value === item.toString())) {
                allValues.push({ value: item.toString(), type: "standard" });
              }
            }
          });
        } else if (value && value.toString().trim() !== "") {
          if (!allValues.some((val) => val.value === value.toString())) {
            allValues.push({ value: value.toString(), type: "standard" });
          }
        }
      });

      setUniqueValues(allValues);
    }
  };

  // Function to detect changes in the filter model
  useEffect(() => {
    const onFilterModelChanged = () => {
      // Call the function to recalculate unique values
      recalculateUniqueValues();
    };

    props.api.addEventListener("filterChanged", onFilterModelChanged);

    return () => {
      props.api.removeEventListener("filterChanged", onFilterModelChanged);
    };
  }, [popoverOpened]);

  // initialize unique values on first render
  useEffect(() => {
    recalculateUniqueValues();
  }, []);

  useEffect(() => {
    if (!popoverOpened) recalculateUniqueValues();
  }, [popoverOpened]);

  const clearSelections = () => {
    setSelectedOptions([]);
    const model = props.api.getFilterModel();
    delete model[props.column.colId];
    props.api.setFilterModel(model);
    props.api.onFilterChanged();
    recalculateUniqueValues(true);
  };

  const toggleSortState = () => {
    if (sortState === "") {
      onSortRequested("asc");
    } else if (sortState === "asc") {
      onSortRequested("desc");
    } else {
      onSortRequested("");
    }
  };

  const onSortChanged = () => {
    if (props.column.isSortAscending()) {
      setSortState("asc");
    } else if (props.column.isSortDescending()) {
      setSortState("desc");
    } else {
      setSortState("");
    }
  };

  const onSortRequested = (order, event) => {
    props.setSort(order);
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
    return () => {
      props.column.removeEventListener("sortChanged", onSortChanged);
    };
  }, []);

  const renderSearchInput = () => (
    <TextInput
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{ marginBottom: "10px" }}
    />
  );

  const renderStickyTopElements = () => (
    <div
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: "#323a49b5",
        padding: "5px",
        paddingTop: "10px",

        zIndex: 2,
      }}
    >
      {renderSearchInput()}
    </div>
  );

  const renderStickyBottomElement = () => (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "#323a49b5",
        padding: "5px",
        paddingTop: "10px",
      }}
    >
      {renderClearButton()}
    </div>
  );

  const renderCheckboxes = () => {
    // Filter unique values based on search term
    uniqueValues.filter((value) =>
      value.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Include selected options even if they are not present in unique values
    const selectedValues = selectedOptions.map((option) => option.value);
    const mergedValues = [
      ...uniqueValues.map((option) => option.value),
      ...selectedValues.map((option) => {
        if (option.value) return option.value;
        else return option;
      }),
    ];

    // Filter values to only return with type "equals" and "standard"
    const refined = mergedValues.filter(
      (value, index) =>
        mergedValues.indexOf(value) === index &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {refined.map((value, index) => (
          <div key={index} style={{ display: "flex" }}>
            <Checkbox
              label={value}
              checked={selectedValues.includes(value)}
              onChange={() =>
                handleCheckboxChange({ value: value, type: "standard" })
              }
            />
          </div>
        ))}
      </div>
    );
  };

  const renderClearButton = () => (
    <Button
      onClick={clearSelections}
      // center horizontally
      style={{
        margin: "0 auto",
        display: "block",

        width: "100%",
      }}
    >
      Clear
    </Button>
  );

  const syncSelectionsWithModel = () => {
    const model = props.api.getFilterModel()[props.column.colId];
    //  listen for changes in the filter model and update the selected options
    const newSelectedOptions = [];

    if (model && model.conditions) {
      model.conditions.forEach((condition) => {
        if (condition.type === "blank") {
          newSelectedOptions.push({ value: "Blank", type: "standard" });
        } else if (condition.type === "equals") {
          newSelectedOptions.push({
            value: condition.filter,
            type: "standard",
          });
        }
      });
    } else if (model && (model.filter || model.type === "blank")) {
      if (model.type === "blank")
        newSelectedOptions.push({ value: "Blank", type: "standard" });
      else newSelectedOptions.push({ value: model.filter, type: "standard" });
    }

    setSelectedOptions(newSelectedOptions);
  };

  const onPopoverOpen = () => {
    syncSelectionsWithModel();
    setPopoverOpened(true);
  };

  let menu = null;

  if (props.filterIcon) {
    menu = (
      <Popover
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
        width={200}
        position="bottom"
        withArrow
        shadow="md"
        zIndex={1000}
      >
        <Popover.Target>
          <div
            ref={refButton}
            className="customHeaderMenuButton"
            onClick={onPopoverOpen}
          >
            <i className={`ag-icon ag-icon-menu`}></i>
          </div>
        </Popover.Target>
        <Portal>
          <Popover.Dropdown
            style={{
              maxHeight: "40vh",
              overflowY: "auto",
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
          >
            {renderStickyTopElements()}
            {renderCheckboxes()}
            {renderStickyBottomElement()}
          </Popover.Dropdown>
        </Portal>
      </Popover>
    );
  }

  const handleCheckboxChange = (value) => {
    const newSelectedOptions = [...selectedOptions];

    const isColumnArray =
      props.api.getColumnDef(props.column.colId).cellDataType === "object"; // Check if column type is array
    if (value === { value: "All", type: "standard" }) {
      if (selectedOptions.some((option) => option.value === "All")) {
        newSelectedOptions.splice(0, newSelectedOptions.length);
      } else {
        uniqueValues.forEach((val) =>
          newSelectedOptions.push({ value: val.value, type: "standard" })
        );
      }
    } else {
      const existingOptionIndex = newSelectedOptions.findIndex(
        (option) => option.value === value.value
      );
      if (existingOptionIndex !== -1) {
        newSelectedOptions.splice(existingOptionIndex, 1);
        const allIndex = newSelectedOptions.findIndex(
          (option) => option.value === "All"
        );
        if (allIndex !== -1) {
          newSelectedOptions.splice(allIndex, 1);
        }
        if (isColumnArray) {
          // Handle array column deletion here if needed
        }
      } else {
        newSelectedOptions.push({ value: value.value, type: "standard" });
      }
    }

    setSelectedOptions(newSelectedOptions);

    const model = props.api.getFilterModel();

    if (newSelectedOptions.some((option) => option.value === "All")) {
      delete model[props.column.colId];
      props.api.setFilterModel(model);
      props.api.onFilterChanged();
      return;
    }

    const newConditions = newSelectedOptions.flatMap(({ value }) => {
      if (value === "Blank") {
        return [{ filterType: "text", type: "blank" }];
      }
      if (isColumnArray && !value.includes(",")) {
        // Apply the 4 filter conditions when the column should be treated as an array
        return [
          {
            filterType: "text",
            type: "equals",
            filter: `${value}`,
          },
          {
            filterType: "text",
            type: "contains",
            filter: `,${value},`,
          },
          {
            filterType: "text",
            type: "startsWith",
            filter: `${value},`,
          },
          {
            filterType: "text",
            type: "endsWith",
            filter: `,${value}`,
          },
        ];
      } else if (isColumnArray && value.includes(",")) {
        return [];
      }
      // Default condition for non-array columns
      return [{ filterType: "text", type: "equals", filter: value }];
    });

    const newModel = {
      ...model,
      [props.column.colId]: {
        filterType: "text",
        operator: "OR",
        conditions: newConditions,
      },
    };

    props.api.setFilterModel(newModel);
    props.api.onFilterChanged();
  };

  const renderSortIcons = () => {
    return (
      <div
        className="ag-sort-indicator-container"
        style={{
          display: "inline-block",
          position: "relative",
          verticalAlign: "text-bottom",
        }}
      >
        {sortState === "asc" && (
          <div
            onClick={(event) => onSortRequested("desc", event)}
            className="ag-sort-indicator-icon ag-sort-descending-icon"
          >
            <i className="ag-icon ag-icon-desc"></i>
          </div>
        )}
        {sortState === "desc" && (
          <div
            onClick={(event) => onSortRequested("", event)}
            className="ag-sort-indicator-icon ag-sort-ascending-icon"
          >
            <i className="ag-icon ag-icon-asc"></i>
          </div>
        )}
        {sortState === "" && (
          <div
            onClick={(event) => onSortRequested("asc", event)}
            className="active"
          >
            <i className="ag-sort-indicator-icon ag-sort-order"></i>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="ag-header-cell-sortable ag-focus-managed"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          // make static for title
        }}
        onClick={toggleSortState}
      >
        {props.displayName}

        {renderSortIcons()}
      </div>

      {menu}
    </div>
  );
};

export default CheckboxSelectFilter;
