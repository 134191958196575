import React, { useEffect } from "react";
import { Checkbox, ActionIcon, Group } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

export function ActionItemCellRender(props) {
  const isChecked = props.selectedRows.includes(props.data.id);

  return (
    <Group noWrap={true}>
      <Checkbox
        checked={isChecked}
        onChange={() => props.selectRow(props.data.id)}
        id={`rowCheckbox-${props.data.id}`}
      />
      <ActionIcon
        size="lg"
        onClick={(e) => {
          e.stopPropagation();
          props.editData(props.data);
        }}
      >
        <IconEdit size="1.1rem" stroke={1.5} />
      </ActionIcon>
    </Group>
  );
}
