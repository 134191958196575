import React, { useEffect, useState } from "react";
import "./App.scss";
import { Loader } from "@mantine/core";
import { defaultColumnConfig } from "static/data/columnDefsConfig";
import { HeaderMiddle } from "components/header/Header";
import { ConfirmModal } from "components/modals/ConfirmModal/ConfirmModal";
import { RateModal } from "components/modals/CreateModal/CreateModal";
import { AGTable } from "components/tables/AGTable/AGTable";
import { LoginModal } from "components/modals/LoginModal/LoginModal";
import { useAuthState } from "./utils/hooks/authHook";
import { useRatesData } from "./utils/hooks/ratesDataHook";
import { useSelectionState } from "./utils/hooks/selectionHook";
import { notify } from "./utils/helpers/notifications";
import { useBulkDeleteMutation } from "./Store/api";

function App() {
  const { user, setUser, isLoggedIn, setIsLoggedIn, token, setToken } =
    useAuthState();
  const {
    ratesData,
    ratesLoading,
    allRatesData: returned,
    allRatesLoading: isLoading,
    fetchData: fetchRates,
    fetchAllRates: getAllRatesFetch,
  } = useRatesData(token);
  const {
    selectedRows,
    setSelectedRows,
    selectRowsBulk,
    showBulkDeleteIcon,
    setShowBulkDeleteIcon,
    selectRow,
  } = useSelectionState();
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [initialColsToShow, setInitialColsToShow] =
    useState(defaultColumnConfig);
  const [showConfirm, setShowConfirm] = useState(false);
  const [getAllDisplayedColumns, setGetAllDisplayedColumns] = useState(null);
  const [colsToShow, setColsToShow] = useState(defaultColumnConfig);
  const [deleteBulk] = useBulkDeleteMutation();
  // TODO: Determine if we will need the gridApi state for manipulation outside of the table
  const [gridApi, setGridApi] = useState();
  console.warn = () => {};

  const fetchData = async () => {
    resetSelectedRows();

    try {
      console.log("fetching rates");
      await fetchRates();
    } catch (err) {
      notify(err.message, "red");
    }
  };

  async function downloadCSV() {
    try {
      const displayedColumns = getAllDisplayedColumns
        ? getAllDisplayedColumns()
        : [];

      // Filter out the 'actionItems' and any other columns you want to ignore
      const filteredDisplayedColumns = displayedColumns.filter(
        (col) => col !== "actionItems"
      );

      // Reorder and filter colsToShow based on displayedColumns
      const reorderedAndFilteredColsToShow = filteredDisplayedColumns
        .map((displayedCol) => {
          return (
            colsToShow.find((colToShow) => colToShow.field === displayedCol) ||
            null
          );
        })
        .filter(Boolean);

      mapDataToTableOrCSV(returned, true, reorderedAndFilteredColsToShow);
    } catch (err) {
      notify(err.message, "red");
    }
  }

  function resetSelectedRows() {
    setSelectedRows([]);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  }

  async function bulkDelete(confirmed) {
    setShowConfirm(false);
    if (!confirmed) {
      return;
    }

    try {
      const { data: response } = await deleteBulk({
        ids: selectedRows,
        token: token,
      });

      if (!response?.message) {
        notify("An error occured", "red");
      } else {
        notify("Delete successful.", "green");
      }
    } catch (err) {
      notify(err.message, "red");
    } finally {
      handleDataUpdate();
    }
  }

  function mapDataToTableOrCSV(inputData, createCSV = false, colsToShow) {
    let dataTable = [];

    if (inputData?.length > 0) {
      inputData.forEach((row) => {
        let dataRow = {
          id: row.id,
          customerName: row.customerName,
          billTo: row.billTo,
          isAsset: row.isAsset ? "Asset" : "Brokerage",
          isBrokerage: row.isBrokerage,
          ruleName: row.ruleName,
          hazmat: row?.isHazmat ? (row.isHazmat === "true" ? "Y" : "N") : "N",
          driverMode: row.isTeamRequired ? "Team" : "Solo",
          trailerMode: row.modes?.trailerMode,
          loadType: row.origin.loadType,
          unloadType: row.destination.loadType,
          orDays: row.origin.days,
          orZip: row.origin.location.zip,
          orState: row.origin.location.state,
          orMetro: row.origin.location.metro,
          orZones: row.origin.location.zone,
          dsDays: row.destination.days,
          dsZip: row.destination.location.zip,
          dsState: row.destination.location.state,
          dsMetro: row.destination.location.metro,
          dsZones: row.destination.location.zone,
          overMiles: row.minimumMiles,
          underMiles: row.maximumMiles,
          desirability: row.isBrokerage
            ? row.desirabilityScoreBrokerage
            : row.isAsset
            ? row.desirabilityScoreAsset
            : undefined,
          percentModifier: row.modifiers.percentModifier,
          flat: row.modifiers.flatModifier,
          maximumCharge: row.modifiers.maximumCharge,
          minimumCharge: row.modifiers.minimumCharge,
          rpmMin: row.modifiers.rpmMin,
          rpmMax: row.modifiers.rpmMax,
          RPM: row.modifiers.RPMModifier,
          effectiveDttm: row.effectiveDttm,
          expirationDttm: row.expirationDttm,
          updatedBy: row.updatedBy,
        };

        dataTable.push(dataRow);
      });
    }

    if (!createCSV) {
      setData(dataTable);
    } else {
      tableToCSV(dataTable, colsToShow);
    }
  }

  function tableToCSV(data, colsToShow) {
    // Filter out the hidden columns and map to their header names
    const visibleCols = colsToShow.filter((col) => !col.hide);
    const headers = visibleCols
      .map((col) => col.headerName || col.field)
      .join(",");

    // Push the headers into csvBuilder
    let csvBuilder = [headers];

    // Create each row based on the visible columns
    for (let row of data) {
      let csvRow = [];
      for (let col of visibleCols) {
        const field = col.field;
        if (Array.isArray(row[field])) {
          csvRow.push(row[field].join(" "));
        } else {
          csvRow.push(row[field] || "");
        }
      }
      csvBuilder.push(csvRow.join(","));
    }

    const csvData = csvBuilder.join("\r\n");
    let csvFile = new Blob([csvData], { type: "text/csv" });

    var temp_link = document.createElement("a");
    temp_link.download = "SuggestedRates.csv";
    var url = window.URL.createObjectURL(csvFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  async function handleDataUpdate() {
    fetchData();
    getAllRatesFetch();
    setShowModal(false);
  }

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
      if (localStorage.getItem(user.id)) {
        const hiddenCols = JSON.parse(localStorage.getItem(user.id));
        const savedCols = colsToShow.map((col) => {
          return hiddenCols.includes(col.field)
            ? {
                ...col,
                hide: true,
              }
            : col;
        });
        setColsToShow(savedCols);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (ratesData) mapDataToTableOrCSV(ratesData);
  }, [ratesData]);

  useEffect(() => {
    setInitialColsToShow(defaultColumnConfig);
  }, []);

  function refreshClicked() {
    fetchData();
  }

  function addClicked() {
    setShowModal(true);
  }

  function deleteClicked() {
    setShowConfirm(true);
  }

  function editDataModal(row) {
    setEditData(row);
    setShowModal(true);
  }

  if (isLoading) {
    return (
      <div className="center">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <div>
      {isLoggedIn && (
        <HeaderMiddle
          props={{
            refreshClicked: refreshClicked,
            addClicked: addClicked,
            deleteClicked: deleteClicked,

            showBulkDeleteIcon: showBulkDeleteIcon,
            downloadClicked: downloadCSV,
            colsToShow: colsToShow,
            setColsToShow: setColsToShow,
            initialColsToShow: initialColsToShow,
            gridApi: gridApi,
            user: user,
          }}
        ></HeaderMiddle>
      )}
      {!!data && isLoggedIn && (
        <AGTable
          data={data}
          selectRow={selectRow}
          setGridApi={setGridApi}
          selectRowsBulk={selectRowsBulk}
          selectedRows={selectedRows}
          editData={editDataModal}
          colsToShow={colsToShow}
          onGetAllDisplayedColumns={(func) =>
            setGetAllDisplayedColumns(() => func)
          }
        ></AGTable>
      )}
      {!isLoggedIn && (
        <LoginModal
          setIsLoggedIn={setIsLoggedIn}
          setUser={setUser}
          setToken={setToken}
        />
      )}
      <RateModal
        onClose={() => {
          setShowModal(false);
          setEditData(null);
        }}
        show={showModal}
        onDataUpdate={handleDataUpdate}
        editData={editData}
        user={user}
        token={token}
      />
      <ConfirmModal
        onClose={(confirmed) => {
          setShowConfirm(false);
          bulkDelete(confirmed);
        }}
        show={showConfirm}
        message={`Are you sure you want to delete ${selectedRows?.length} items?`}
      />
    </div>
  );
}

export default App;
